const Fragment = {
    USER_ACCOUNT_SUBSCRIPTION: `{
        status
        currentPeriodStart
        currentPeriodEnd
        trialStart
        trialEnd
        cancelAt
        cardBrand
        cardLast4
        cardExpMonth
        cardExpYear
        subscriptionPlanPrice {
            id
            price
            billingPeriod
        }
        subscriptionDetails {
            state
            statusLabel
            statusDescription
            canCancel
            isPaymentMethodVisible
            isUserAccountBlocked
        }
    }`,
};

const Query = {
    GET_TOKEN: `query GetToken($username: String!, $password: String!) {
        token(username: $username, password: $password) {
            accessToken
            refreshToken
            expiresIn
            newUser
            userAccount {
                id
                username
                hasPassword
                hasFacebookLogin
                hasGoogleLogin
                user {
                    firstName
                    lastName
                    email
                    birthyear
                    newsletter
                }
                userAccountGameConfigs {
                    id
                    numberOfCardsToDeal
                }
            }
        }
    }`,
    GET_TOKEN_FROM_OAUTH_LOGIN: `query GetToken($provider: String!, $accessToken: String, $code: String) {
        tokenFromOAuthLogin(provider: $provider, accessToken: $accessToken, code: $code) {
            accessToken
            refreshToken
            expiresIn
            newUser
            userAccount {
                id,
                username
                hasPassword
                hasFacebookLogin
                hasGoogleLogin
                user {
                    firstName
                    lastName
                    email
                    birthyear
                    newsletter
                }
                userAccountGameConfigs {
                    id
                    numberOfCardsToDeal
                }
                createdAt
            }
        }
    }`,
    COMPLETE_USER_ACCOUNT_INFO: `mutation CompleteUserAccountInfo($input: CompleteUserAccountInfoInput!) {
        completeUserAccountInfo(input: $input) {
            id,
            username
            hasPassword
            hasFacebookLogin
            hasGoogleLogin
            user {
                firstName
                lastName
                email
                birthyear
                newsletter
            }
            userAccountGameConfigs {
                id
                numberOfCardsToDeal
            }
            createdAt
        }
    }`,
    EDIT_USER_ACCOUNT_INFO: `mutation EditUserAccountInfo($input: EditUserAccountInfoInput!) {
        editUserAccountInfo(input: $input) {
            id,
            username
            hasPassword
            hasFacebookLogin
            hasGoogleLogin
            user {
                firstName
                lastName
                email
                birthyear
                newsletter
            }
            userAccountGameConfigs {
                id
                numberOfCardsToDeal
            }
            createdAt
        }
    }`,
    EDIT_USER_ACCOUNT_PASSWORD: `mutation EditUserAccountInfo($input: EditUserAccountPasswordInput!) {
        editUserAccountPassword(input: $input) {
            id,
            username
            hasPassword
            hasFacebookLogin
            hasGoogleLogin
            user {
                firstName
                lastName
                email
                birthyear
                newsletter
            }
            userAccountGameConfigs {
                id
                numberOfCardsToDeal
            }
            createdAt
        }
    }`,
    CREATE_USER_ACCOUNT: `mutation CreateUserAccount($input: CreateUserAccountInput!) {
        createUserAccount(input: $input) {
            accessToken
            refreshToken
            expiresIn
            newUser
            userAccount {
                id,
                username
                hasPassword
                hasFacebookLogin
                hasGoogleLogin
                user {
                    firstName
                    lastName
                    email
                    birthyear
                    newsletter
                }
                userAccountGameConfigs {
                    id
                    numberOfCardsToDeal
                }
            }
        }
    }`,
    INVITE_USER_ACCOUNT_FOR_GAME: `mutation InviteUserAccountForGame($input: InviteUserAccountForGameInput!) {
        inviteUserAccountForGame(input: $input) {
            id
            username
            user {
                firstName
                lastName
                email
            }
        }
    }`,
    JOIN_FROM_GAME_TOKEN: `query JoinFromGameToken($gameToken: String!) {
        joinFromGameToken(gameToken: $gameToken) {
            token {
                accessToken
                refreshToken
                expiresIn
                userAccount {
                    id,
                    username
                    hasPassword
                    hasFacebookLogin
                    hasGoogleLogin
                    user {
                        firstName
                        lastName
                        email
                        birthyear
                        newsletter
                    }
                    userAccountGameConfigs {
                        id
                        numberOfCardsToDeal
                    }
                    createdAt
                }
            }
            game {
                id
                alphanumericId
            }
        }
    }`,
    REQUEST_CHANGE_PASSWORD: `query RequestChangePassword($email: String!) {
        requestChangePassword(email: $email)
    }`,
    CHANGE_PASSWORD: `query ChangePassword($token: String!, $password: String!) {
        changePassword(token: $token, password: $password) {
            id
        }
    }`,
    CREATE_GAME: `mutation CreateGame($input: CreateGameInput!) {
        createGame(input: $input) {
            id
            alphanumericId
            gameMessages
            partialStates
        }
    }`,
    CREATE_DUPLICATE_TOURNAMENT_GAME: `mutation CreateGame($input: CreateDuplicateTournamentGameInput!) {
        createDuplicateTournamentGame(input: $input) {
            id
            alphanumericId
            gameMessages
            partialStates
        }
    }`,
    VIEWER_ME: `query Viewer {
        viewer {
            me {
                id
                username
                hasPassword
                hasFacebookLogin
                hasGoogleLogin
                user {
                    firstName
                    lastName
                    email
                    birthyear
                    newsletter
                }
                userAccountGameConfigs {
                    id
                    numberOfCardsToDeal
                }
            }
        }
    }`,
    VIEWER_FRIENDS: `query Viewer($first: Int, $after: String) {
        viewer {
            friends(first: $first, after: $after) {
                edges {
                    cursor
                    node {
                        id
                        username
                        user {
                            firstName
                            lastName
                            email
                        }
                    }
                }
                pageInfo {
                    endCursor
                }
            }
        }
    }`,
    VIEWER_GAMES: `query ViewerGames($last: Int, $cursor: String, $type: Int) {
        viewer {
            me {
                id
            }
            games(last: $last before: $cursor type: $type) {
                edges {
                    cursor
                    node {
                        id
                        alphanumericId
                        currentTurn
                        nextTurn
                        currentRound
                        isSolo
                        isOpenGame
                        totalTurns
                        hasEnded
                        duplicateConfigGame {
                            id
                            date
                        }
                        duplicateConfigGameName
                        breakStartDate
                        breakRequesterTeamPlayer {
                            id
                            firstName
                        }
                        breakDuration
                        createdAt
                        endDate
                        winnerTeam {
                            id
                        }
                        gameTeams {
                            id
                            playerSeats
                            gameTeamScores {
                                round
                                bonusCount
                                baseCount
                                secondCount 
                            }
                            teamPlayers {
                                id
                                seat
                                isViewer
                                isCurrentTurn
                                firstName
                                isNameInitialRepeated
                                archived
                                removed
                                userAccount {
                                    user {
                                        firstName
                                    }
                                }
                            }
                        }
                    }
                }
                pageInfo {
                    endCursor
                }
            }
        }
    }`,
    VIEWER_GAME: `query Game($alphanumericId: String!) {
        viewer {
            game(alphanumericId: $alphanumericId) {
                id
                alphanumericId
                modifiedAt
                hasStarted
                hasEnded
                hasPartialStates
                gameMessages
                partialStates
                previousRound
                currentRound
                isRoundFinished
                isSolo
                isOpenGame
                totalTurns
                duplicateConfigGame {
                    id
                    date
                }
                duplicateConfigGameName
                breakStartDate
                hasAnyTeamMarkedRoundStalemate
                breakRequesterTeamPlayer {
                    id
                    firstName
                    userAccount {
                        id
                    }
                }
                breakDuration
                drawPileTotalCards
                discardPileTotalCards
                discardPileTopCard {
                    cardRank {
                        value
                        name
                        sortOrder
                    }
                    cardSuit {
                        name
                        letter
                        isRed
                        symbol
                    }
                }
                userAccount {
                    user {
                        firstName
                    }
                }
                turnsSummary {
                    teamPlayer {
                        id
                        firstName
                    }
                    round
                    turn
                    messages
                }
                gameTeams {
                    id
                    playerSeats
                    naturalCanastasLeft
                    unnaturalCanastasLeft
                    hasAnyPlayerMarkedStalemate
                    partialCurrentScore
                    gameTeamScores {
                        round
                        bonusCount
                        baseCount
                        secondCount
                    }
                    melds {
                        id
                        isNatural
                        numberOfWildCardsAllowed
                        cardRank {
                            value
                            name
                            sortOrder
                        }
                        meldCards {
                            committed
                            card {
                                id
                                cardRank {
                                    value
                                    name
                                    sortOrder
                                }
                                cardSuit {
                                    name
                                    letter
                                    isRed
                                    symbol
                                }
                                meldValue
                                countValue
                                inHandValue
                                isWild
                            }
                        }
                    }
                    teamPlayers {
                        id
                        isViewer
                        isCurrentTurn
                        isNextTurn
                        totalCards
                        joinedGame
                        ready
                        joinedRound
                        drawnInTurn
                        pickedUpPileInTurn
                        isPickingUpPile
                        isPlayingFoot
                        isNameInitialRepeated
                        firstName
                        askPartnerToGoOutStatus
                        archived
                        removed
                        userAccount {
                            id
                            user {
                                firstName
                            }
                        }
                        seat
                        hand {
                            isFoot
                            handCards {
                                card {
                                    id
                                    cardRank {
                                        value
                                        name
                                        sortOrder
                                    }
                                    cardSuit {
                                        name
                                        letter
                                        isRed
                                        symbol
                                    }
                                    meldValue
                                    countValue
                                    inHandValue
                                    isWild
                                }
                            }
                        }
                    }
                }
                coachingMarks {
                    coachingMark {
                        id
                        name
                        enabled
                        priority
                        config
                        parsedPages
                    }
                    read
                    show
                }
                gameConfig {
                    cardsToDeal
                    totalDecks
                    totalPickupCards
                    red3s
                    black3s
                    maxWildCards
                    addToCompletedCanastas
                    naturalCanastasNeeded
                    unnaturalCanastasNeeded
                    askToGoOut
                    swipeCards
                }
            }
        }
    }`,
    VIEWER_TUTORIAL_SCREENS: `query {
        viewer {
            tutorialScreens {
                tutorialScreen {
                    id
                    name
                    sequence
                    enabled
                    isFirst
                    isLast
                    screenVisibility
                }
                read
            }
        }
    }`,
    READ_TUTORIAL: `mutation ReadTutorialScreen($input: ReadTutorialScreenInput!) {
        readTutorialScreen(input: $input) {
            tutorialScreen {
                id
                name
            }
            read
        }
    }`,
    VIEWER_ANNOUNCEMENTS: `query {
        viewer {
            announcements {
                id
                name
                category
                priority
                enabled
                config
                parsedContent
            }
        }
    }`,
    READ_ANNOUNCEMENT: `mutation ReadAnnouncement($input: ReadAnnouncementInput!) {
        readAnnouncement(input: $input) {
            id
            name
            category
            priority
            enabled
            config
            parsedContent
        }
    }`,
    COMPUTER_PLAYER_PERSONAS: `query ComputerPlayerPersonas {
        computerPlayerPersonas {
            id
            name
            description
            enabled
            visible
        }
    }`,
    VIEWER_SETTINGS: `query {
        viewer {
            settings {
                showCoachingMarks
                showTutorial
                showRankLabels
            }
        }
    }`,
    SET_SETTINGS: `mutation SetSettings($input: SetSettingsInput!) {
        setSettings(input: $input) {    
            showTutorial
            showCoachingMarks
            showRankLabels
        }
    }`,
    VIEWER_SUBSCRIPTION: `query Subscription {
        viewer {
            subscription ${Fragment.USER_ACCOUNT_SUBSCRIPTION}
        }
    }`,
    VIEWER_USER_ACCOUNT_DAILY_TOURNAMENT: `query UserAccountDuplicateDailyTournament {
        viewer {
            userAccountDuplicateDailyTournament {
                startedSoloGame
                startedTeamGame
                completedSoloGame
                completedTeamGame
                soloGame {
                    id
                    alphanumericId
                }
                teamGame {
                    id
                    alphanumericId
                }
                currentDuplicateConfigGame {
                    displayNameGeneric
                    date
                    endDate
                }
                hasEverPlayedTournament
            }
        }
    }`,
    VIEWER_DAILY_TOURNAMENT_LEADERBOARD: `query Leaderboard($date: String!, $solo: Boolean!, $short: Boolean!, $mode: Int!) {
        viewer {
            duplicateDailyTournamentLeaderboard(date: $date, solo: $solo, short: $short, mode: $mode) {
                rank
                firstName
                duplicateScore
                teamScore
                winMargin
                points
                percentageTop
                games
            }
        }
    }`,
    VIEWER_METRICS: `query Metrics($solo: Boolean, $byOpponent: Boolean, $byPartner: Boolean, $canastasRequired: String) {
        viewer {
            metrics(solo: $solo, byOpponent: $byOpponent, byPartner: $byPartner, canastasRequired: $canastasRequired) {
                metrics {
                  codeName
                  displayName
                  value
                  percentile
                }
                dimensions {
                  codeName
                  displayName
                  value
                }
            }
        }
    }`,
    PERFORM_GAME_ACTION: `mutation PerformGameAction($input: PerformGameActionInput!) {
        performGameAction(input: $input) {
            id
            alphanumericId
            hasPartialStates
            gameMessages
            partialStates
        }
    }`,
    REQUEST_BREAK: `mutation RequestBreak($input: RequestBreakInput!) {
        requestBreak(input: $input)
    }`,
    FINISH_BREAK: `mutation FinishBreak($input: FinishBreakInput!) {
        finishBreak(input: $input)
    }`,
    TOGGLE_ARCHIVE_GAME: `mutation ToggleAchiveGame($gameAlphanumericId: String!) {
        toggleArchiveGame(gameAlphanumericId: $gameAlphanumericId)
    }`,
    TOGGLE_REMOVE_GAME: `mutation ToggleRemoveGame($gameAlphanumericId: String!) {
        toggleRemoveGame(gameAlphanumericId: $gameAlphanumericId)
    }`,
    SAVE_USER_ACCOUNT_GAME_CONFIG: `mutation SaveUserAccountGameConfig($input: SaveUserAccountGameConfigInput!) {
        saveUserAccountGameConfig(input: $input) {
            userAccountGameConfigs {
                id
                numberOfCardsToDeal
            }
        }
    }`,
    CREATE_DEBUG_GAME: `mutation CreateDebugGame($data: String!) {
        createDebugGame(data: $data) {
            id
            alphanumericId
        }
    }`,
    GENERAL_APP_INFO: `query {
        generalAppInfo {
            generalRules
            privacy
            terms
            submitSupportRequestUrl
            duplicateRules
            gameStatsOverview
            gameStatsInsights
            freePlanScreenConfig
            annualSave
        }
    }`,
    CURRENT_APP_VERSION: `query {
        generalAppInfo {
            appVersion
            appVersionMessage
        }
    }`,
    SUBSCRIPTION_PLANS: `query {
        subscriptionPlans {
            id
            name
            description
            subscriptionPlanPrices {
                id
                price
                billingPeriod
            }
        }
    }`,
    SUBSCRIBE_TO_SUBSCRIPTION_PLAN: `mutation SubscribeToSubscriptionPlanPrice($input: SubscribeToSubscriptionPlanPriceInput!) {
        subscribeToSubscriptionPlanPrice(input: $input) ${Fragment.USER_ACCOUNT_SUBSCRIPTION}
    }`,
    CANCEL_SUBSCRIPTION: `mutation CancelSubscription {
        cancelSubscription ${Fragment.USER_ACCOUNT_SUBSCRIPTION}
    }`,
    READ_COACHING_MARK: `mutation ReadCoachingMark($input: ReadCoachingMarkInput!) {
        readCoachingMark(input: $input)
    }`,
    HIDE_COACHING_MARK: `mutation HideCoachingMark($input: HideCoachingMarkInput!) {
        hideCoachingMark(input: $input)
    }`,
};

export default Query;
