import React, {useCallback, useContext, useEffect, useState} from "react";
import StyleContext from "../../StyleContext";
import StandardText from "../../sharedComponents/standardText/StandardText";
import {TextInput, TouchableOpacity, View} from "react-native";
import LoadingButton from "../../sharedComponents/loadingButton/LoadingButton";
import SelectableList from "../../sharedComponents/selectableList/SelectableList";
import Colors from "../../styles/colors";
import {connect} from "react-redux";
import {fetchViewerFriends} from "../../redux/viewer/actions/friends";
import {useNavigation} from "@react-navigation/native";
import Api from "../../services/Api";
import {setSelectedPlayers, startSelectingPlayerForPosition, stopSelectingPlayerForPosition} from "../../redux/viewer/gameSetupSlice";
import {useFocusEffect} from "@react-navigation/core";
import {LoadingContext} from "../../context/LoadingContext";
import Spacing from "../../styles/spacing";
import Checkbox from "../../sharedComponents/checkbox/Checkbox";
import {fetchComputerPlayerPersonas} from "../../redux/viewer/computerPlayerSlice";
import Svg from "react-native-svg";

const GameSetupPlayers = ({
                              dispatch,
                              friends,
                              computerPlayerPersonas,
                              userAccount,
                              accessToken,
                              gameSetup,
                              onContinuePress,
                              invitedFriends = [],
                              setInvitedFriends = () => {},
                          }) => {
    const styleContext = useContext(StyleContext);
    const loadingContext = useContext(LoadingContext);

    const [availableFriends, setAvailableFriends] = useState([]);
    const [newPlayerFirstName, setNewPlayerFirstName] = useState('');
    const [newPlayerEmail, setNewPlayerEmail] = useState('');
    const [newPlayerLoading, setNewPlayerLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const teamCount = 2;
    const seatsPerTeam = gameSetup.setup.numberOfPlayers / teamCount;
    const positionBeingSelected = gameSetup.positionBeingSelected;


    console.log('gameSetup', gameSetup);
    const [players, setPlayers] = useState(userAccount && (!gameSetup.setup.players || gameSetup.setup.players.length === 0) ? [{
        ...userAccount,
        team: 1,
        seat: 0,
    }] : [...gameSetup.setup.players]);

    useFocusEffect(useCallback(() => {
        loadingContext.setScreenLoading(false)
    }, []));

    useFocusEffect(useCallback(() => {
        if (userAccount) {
            dispatch(fetchViewerFriends(null));
            dispatch(fetchComputerPlayerPersonas());
        }
    }, [dispatch, userAccount]));

    useFocusEffect(useCallback(() => {
        const ids = [];
        const mergedFriends = [...friends, ...invitedFriends].map((edge) => {
            return (edge.node)
        }).filter(f => {
            if (!ids.includes(f.id)) {
                ids.push(f.id);
                return true;
            }

            return false;
        });

        setAvailableFriends(mergedFriends);
    }, [friends, invitedFriends]));

    useFocusEffect(useCallback(() => {
    }, [availableFriends]));

    useFocusEffect(useCallback(() => {
        dispatch(setSelectedPlayers(players));
    }, [players]));

    const handleAddNewPlayerButton = async () => {
        setNewPlayerLoading(true);
        setErrorMessage(null);
        try {
            const userAccount = await Api.inviteNewUserAccount(accessToken, newPlayerFirstName, newPlayerEmail);
            setNewPlayerFirstName('');
            setNewPlayerEmail('');
            setInvitedFriends([...invitedFriends, {node: userAccount}]);
        } catch (error) {
            setErrorMessage(error.message);
        }
        setNewPlayerLoading(false);
    };

    const addPlayer = (player) => {
        console.log('adding player', { player });
        const replacedPlayer = players.find(p => p.id === player.id || (p.team === player.team && p.seat === player.seat));
        let newPlayers = replacedPlayer ? players.filter(p => p.id !== replacedPlayer.id) : [...players];
        newPlayers.push(player);

        if (userAccount && replacedPlayer && replacedPlayer.id === userAccount.id) {
            let newUserAccountSeat = replacedPlayer.seat + 1;
            let newUserAccountTeam = replacedPlayer.team;

            if (newUserAccountSeat >= seatsPerTeam) {
                newUserAccountSeat = 0;
                newUserAccountTeam++;
            }

            if (newUserAccountTeam > teamCount) {
                newUserAccountTeam = 1;
                newUserAccountSeat = 0;
            }

            newPlayers = newPlayers.filter(p => p.team !== newUserAccountTeam || p.seat !== newUserAccountSeat);
            newPlayers.push({
                ...userAccount,
                team: newUserAccountTeam,
                seat: newUserAccountSeat,
            });
        }

        setPlayers(newPlayers);
    };

    const addAvailableFriend = (friend) => {
        if (positionBeingSelected) {
            addPlayer({
                ...friend,
                team: positionBeingSelected.team,
                seat: positionBeingSelected.seat,
            });
        }
        dispatch(stopSelectingPlayerForPosition());
    };

    const addComputerPlayer = (cpp) => {
        if (positionBeingSelected) {
            addPlayer({
                ...cpp,
                id: -cpp.id,
                team: positionBeingSelected.team,
                seat: positionBeingSelected.seat,
            });
        }
        dispatch(stopSelectingPlayerForPosition());
    };

    if (!positionBeingSelected) {
        const teamViews = [];
        for (let team = 1; team <= teamCount; team++) {
            const seatViews = [];
            for (let seat = 0; seat < seatsPerTeam; seat++) {
                const player = players.find(p => p.team === team && p.seat === seat);
                const name = player ? (player.id === userAccount?.id ? 'You' : player.user?.firstName || player.name) : 'Open';
                const username = player ? "(" + (player.userAccount?.userAccount?.username || player.userAccount?.username || player.username || 'Computer') + ")" : '';

                seatViews.push(
                    <TouchableOpacity
                        key={seat}
                            style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: seat === 0 ? 0 : 8,
                            backgroundColor: Colors.background,
                            borderColor: player ? Colors.onBackgroundBorder : Colors.primary4,
                            borderWidth: 1,
                            borderRadius: 8,
                            padding: 8,
                            paddingLeft: 8,
                            paddingRight: 8,
                        }}
                        onPress={() => dispatch(startSelectingPlayerForPosition({team: team, seat: seat}))}
                    >
                        <StandardText style={{fontSize: 20, color: Colors.onBackground}}>
                            {name}
                        </StandardText>
                        <StandardText style={{fontSize: 20, color: Colors.onBackground3, marginLeft: 8}}>
                            {username}
                        </StandardText>
                    </TouchableOpacity>
                );
            }
            teamViews.push(
                <View key={team} style={{
                    flexDirection: 'column',
                    width: '100%',
                    maxWidth: 420,
                    minWidth: 200,
                    justifyContent: 'center',
                    alignItems: 'stretch',
                    backgroundColor: '#efefef',
                    borderRadius: 8,
                    marginTop: team == 1 ? 0 : 16,
                    padding: 8,
                }}>
                    {seatViews}
                </View>
            );
        }

        return (
            <View style={{
                flexDirection: 'column',
                width: '100%',
                justifyContent: 'stretch',
                alignItems: 'stretch',
                flexGrow: 1,
            }}>
                <View style={ownStyles.seatsContainer}>
                    <View style={{alignItems: 'center'}}>
                        <StandardText style={{fontSize: 20, weight: 500}}>
                            Select an Open seat to add a human or computer player, or leave it open so any player can join:
                        </StandardText>
                    </View>

                    <View style={{
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: 16,
                        marginBottom: 16,
                    }}>
                        {teamViews}
                    </View>
                </View>

                <View style={{...ownStyles.continueButtonContainer}}>
                    <View style={{
                        width: '100%',
                        maxWidth: 420,
                        minWidth: 200,
                        paddingStart: Spacing.base,
                        paddingEnd: Spacing.base
                    }}>
                        <LoadingButton label={'Continue'} onPress={onContinuePress}/>
                    </View>
                </View>
            </View>
        );
    }

    return (
        <View style={{
            flexDirection: 'column',
            width: '100%',
            maxWidth: 420,
            minWidth: 200,
            justifyContent: 'center',
            alignItems: 'stretch'
        }}>
            <View style={{flexDirection: 'column', flexGrow: 1, marginTop: 10}}>
                <View style={{alignItems: 'center'}}>
                    <StandardText style={{fontSize: 20, weight: 500}}>
                        Invite a new player...
                    </StandardText>
                </View>

                <View style={{alignItems: 'stretch', justifyContent: 'center'}}>
                    <View style={{marginTop: 0, alignItems: 'stretch', padding: Spacing.base}}>
                        <TextInput
                            style={[styleContext.input, {fontFamily: 'Roboto_400Regular', marginBottom: Spacing.base}]}
                            placeholder={'First Name'}
                            value={newPlayerFirstName}
                            onChangeText={(text => {
                                setNewPlayerFirstName(text)
                            })} placeholderTextColor={'#999999'}/>
                        <TextInput style={[styleContext.input, {fontFamily: 'Roboto_400Regular', margin: 0}]}
                                   placeholder={'Email or Username'}
                                   value={newPlayerEmail}
                                   onChangeText={(text => {
                                       setNewPlayerEmail(text)
                                   })} placeholderTextColor={'#999999'}/>

                        <View>
                            <LoadingButton loading={newPlayerLoading} label={'Invite new player'}
                                           onPress={handleAddNewPlayerButton}
                                           backgroundColor={Colors.primary4}
                                           labelColor={Colors.onPrimary}
                                           padding={Spacing.base}
                                           showChevron={false}
                                           disabled={newPlayerLoading || !newPlayerFirstName || !newPlayerEmail}
                            />
                            {errorMessage && 
                                <View style={{...ownStyles.resultMessageContainer}}>
                                    <StandardText style={{
                                        ...ownStyles.resultMessage,
                                        color: Colors.error
                                    }}>{errorMessage}</StandardText>
                                </View>
                            }
                        </View>
                    </View>
                </View>

                <View style={{alignItems: 'center'}}>
                    <StandardText style={{fontSize: 20, weight: 500}}>
                        ... or select from list
                    </StandardText>
                    <StandardText style={{fontSize: 16, weight: 500}}>
                        (2 or 4 players)
                    </StandardText>
                </View>

                <View style={[ownStyles.listContainer]}>

                    {computerPlayerPersonas.map((cpp) => (
                        <TouchableOpacity key={cpp.id} onPress={() => {addComputerPlayer(cpp)}} style={{...ownStyles.item, flexDirection: 'row', ...ownStyles.itemHighlight, opacity: 1}}>
                            <View style={{...ownStyles.checkboxWrapper, flexDirection: 'row'}}>
                                <View style={{marginStart: 3, marginEnd: 8}}>
                                    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M13 13V19H11V13H5V11H11V5H13V11H19V13H13Z" fill={Colors.background}/>
                                    </Svg>
                                </View>
                                <View style={{flex: 1}}>
                                    <StandardText style={{color: Colors.background, marginBottom: 5}}>{cpp.name}</StandardText>
                                    <StandardText style={{color: Colors.background, fontSize: 14, lineHeight: 18}}>{cpp.description}</StandardText>
                                </View>
                            </View>
                        </TouchableOpacity>
                    ))}

                    {availableFriends.map((friend) => (
                        <TouchableOpacity
                            key={friend.id}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginTop: 8,
                                backgroundColor: Colors.background,
                                borderColor: Colors.onBackgroundBorder,
                                borderWidth: 1,
                                borderRadius: 8,
                                padding: 8,
                                paddingLeft: 8,
                                paddingRight: 8,
                            }}
                            onPress={() => addAvailableFriend(friend)}
                        >
                            <StandardText style={{fontSize: 20, color: Colors.onBackground}}>
                                {friend.user?.firstName}
                            </StandardText>
                            <StandardText style={{fontSize: 20, color: Colors.onBackground3, marginLeft: 8}}>
                                ({friend.username})
                            </StandardText>
                        </TouchableOpacity>
                    ))}
                </View>

            </View>
        </View>
    )
};

const mapStateToProps = (state) => {
    return {
        userAccount: state.auth.login.userAccount,
        accessToken: state.auth.login.accessToken,
        friends: state.viewer.friends.data,
        friendsCursor: state.viewer.friends.cursor,
        friendsLoading: state.viewer.friends.loading,
        gameSetup: state.viewer.gameSetup,
        computerPlayerPersonas: state.viewer.computerPlayer.computerPlayerPersonas,
        computerPlayerPersonasLoading: state.viewer.computerPlayer.loading
    }
};

export default connect(mapStateToProps)(GameSetupPlayers);

const ownStyles = {
    listContainer: {
        flex: 1,
        marginTop: Spacing.base,
        padding: Spacing.base
    },
    errorMessageContainer: {
        alignItems: 'center',
        margin: 10,
        marginTop: 0
    },
    errorMessage: {
        color: Colors.error
    },
    continueButtonContainer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        alignItems: 'center',
        backgroundColor: Colors.background,
    },
    item: {
        backgroundColor: Colors.background,
        borderWidth: 1,
        borderRadius: 8,
        borderColor: Colors.onBackgroundBorder2,
        marginTop: 3,
        marginBottom: 3,
        padding: 8
    },
    itemSelected: {
        borderColor: Colors.primary4,
    },
    itemHighlight: {
        borderColor: Colors.secondary3,
        backgroundColor: Colors.secondary,
    },
    checkboxWrapper: {
        width: '100%',
    },
    resultMessageContainer: {
        minHeight: 30,
        padding: Spacing.base,
        backgroundColor: Colors.backgroundSecondary,
        borderRadius: 6,
        justifyContent: 'center',
        alignItems: 'center'
    },
    resultMessage: {
        fontSize: 14,
    },

    seatsContainer: {
        flexDirection: 'column',
        flexGrow: 1,
        marginTop: 16,
        paddingTop: 16,
        backgroundColor: 'white',
        padding: 12,
        borderRadius: 10,
    },
};
