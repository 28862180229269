import React from 'react';
import {Image, TouchableOpacity, View} from "react-native";
import Colors from "../../styles/colors";
import LogoNoText from "../../icons/LogoNoText";
import StandardText from "../../sharedComponents/standardText/StandardText";
import Spacing from "../../styles/spacing";
import PlayerBadge from "./PlayerBadge";
import CheckIcon from "../../icons/CheckIcon";
import GameHelper from "../GameHelper";
import Svg, {Defs, LinearGradient, Path, Stop} from "react-native-svg";
import InfoIcon from "../../icons/InfoIcon";
import blurredSuitsBg from './../../../assets/blurred_suits_bg2.png';
import TutorialIcon from "../../icons/TutorialIcon";
import Tooltip from "../../sharedComponents/tooltip/Tooltip";
import { APP_VERSION, DEBUG } from "../../env.json";

const WaitingForPlayers = ({game, onRulesPress, onTutorialPress, onCancelPress}) => {
    return (
        <View style={{...ownStyles.container}}>

            <View style={{position: 'absolute', top: 15, left: 15, zIndex: 1}}>
                <TouchableOpacity style={{...ownStyles.rulesButton}} onPress={onRulesPress}>
                    <InfoIcon size={24} color={'rgba(70, 74, 83, 1)'}/>
                    <StandardText style={{...ownStyles.rulesButtonText}}>Rules</StandardText>
                </TouchableOpacity>

                <TouchableOpacity style={{...ownStyles.rulesButton, marginTop: 15}} 
                    onPress={onTutorialPress}>
                    <TutorialIcon size={21} color={'rgba(70, 74, 83, 1)'}/>
                    <StandardText style={{...ownStyles.rulesButtonText}}>Tutorial</StandardText>
                </TouchableOpacity>
            </View>

            <View style={{position: 'absolute', top: 15, right: 15, zIndex: 1}}>
                <TouchableOpacity onPress={onCancelPress}>
                    <StandardText style={{fontSize: 14}}>Cancel</StandardText>
                </TouchableOpacity>
            </View>

            <View style={{...ownStyles.blurredBackground}}>
                <Image source={{uri: blurredSuitsBg}} style={{width: 526, height: 923}}/>
            </View>

            <View>
                <LogoNoText size={156}/>
            </View>

            <View style={{...ownStyles.idContainer}}>
                <StandardText style={{...ownStyles.idText}}>
                    Game ID: {game.id} {DEBUG && (<> / {APP_VERSION}</>)}
                </StandardText>
            </View>

            <View style={{...ownStyles.waitingContainer}}>
                <StandardText style={{...ownStyles.waitingText}}>
                    {`You're all set!\nWaiting for players...`}
                </StandardText>
            </View>

            <View>
                <View style={{flexDirection: 'row'}}>
                    {
                        <View style={{flexDirection: 'row', margin: Spacing.extraLarge, alignItems: 'flex-start'}}>
                            {renderTeamPlayers(GameHelper.getViewerTeam(game), 0, GameHelper.getViewerTeamOpenSeats(game))}
                            <View style={{...ownStyles.vsContainer}}>
                                <View style={{alignItems: 'center'}}>
                                    <View>
                                        <Svg width="2" height="62" viewBox="0 0 2 62" fill="none">
                                            <Path opacity="0.4" d="M1 0.5V62" stroke="url(#paint0_linear)"/>
                                            <Defs>
                                                <LinearGradient id="paint0_linear" x1="1.99997" y1="1.87778e-08"
                                                                x2="1.99997" y2="62" gradientUnits="userSpaceOnUse">
                                                    <Stop stopColor="white"/>
                                                    <Stop offset="0.567708" stopColor="#11204A"/>
                                                    <Stop offset="1" stopColor="#11204A" stopOpacity="0"/>
                                                </LinearGradient>
                                            </Defs>
                                        </Svg>
                                    </View>
                                    <StandardText style={{
                                        marginTop: -42,
                                        backgroundColor: Colors.background,
                                        zIndex: 1
                                    }}>vs</StandardText>
                                </View>
                            </View>
                            {renderTeamPlayers(GameHelper.getOpponentTeam(game), 1, GameHelper.getOppnentTeamOpenSeats(game))}
                        </View>
                    }
                </View>
            </View>

        </View>
    );
};

const ownStyles = {
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: Colors.background,
        justifyContent: 'center',
        alignItems: 'center'
    },
    blurredBackground: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 200
    },
    idContainer: {
        borderRadius: 4,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        background: 'linear-gradient(90deg, #8850FF 0%, #E67E5D 100%)',
        marginTop: -35
    },
    idText: {
        color: Colors.onPrimary,
        fontSize: 20,
        weight: 500
    },
    waitingContainer: {
        marginTop: Spacing.large,
        marginBottom: Spacing.extraLarge
    },
    waitingText: {
        fontSize: 24,
        weight: 500,
        textAlign: 'center',
        lineHeight: 32
    },
    joinedCheckContainer: {
        width: 24,
        height: 24,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        bottom: -2,
        right: -2,
        padding: 2,
        borderRadius: 20,
        borderWidth: 2,
        borderColor: Colors.success,
        backgroundColor: Colors.background
    },
    tutorialIconContainer: {
        width: 26,
        height: 26,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        bottom: -2,
        right: -2,
        padding: 2,
        backgroundColor: 'transparent'
    },
    vsContainer: {
        marginTop: 0,
        marginStart: Spacing.large,
        marginEnd: Spacing.large,
    },
    rulesButton: {
        flexDirection: 'row',
        borderWidth: 1,
        borderRadius: 5,
        borderColor: 'rgb(190,190,190)',
        padding: 8,
        alignItems: 'center',
        backgroundColor: Colors.background
    },
    rulesButtonText: {
        marginStart: 5
    },
    playerBadge: {
        width: 48,
        height: 48,
        borderRadius: 100,
        borderWidth: 2,
        borderColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 4,
    },
};

const getTeamColor = (teamIndex) => {
    switch (teamIndex) {
        case 0:
            return 'rgba(94, 143, 255, 1)'
        case 1:
            return 'rgba(237, 102, 99, 1)'
        case 2:
            return 'rgba(17, 32, 74, 1)';
    }
};

const renderBadge = (player, teamIndex) => {
    if (!player) {
        return <View
                style={{...ownStyles.playerBadge, backgroundColor: 'transparent',
                    borderColor: getTeamColor(teamIndex),
                }}>
            <StandardText style={{
                color: getTeamColor(teamIndex),
                weight: 500,
            }}>
                +
            </StandardText>
        </View>;
    }

    return (
        <View>
            <View style={{margin: Spacing.small}}>
                <PlayerBadge player={player}
                             bare={true}
                             size={48}
                             fontSize={20}
                             gapSize={3}
                             activePlainColor={getTeamColor(teamIndex)}
                             inactivePlainColor={getTeamColor(teamIndex)}
                             showBackgroundOnActive={true}
                             showBackgroundOnInactive={false}
                             active={!player.joinedGame}/>
                {player.joinedGame && player.ready &&
                <View style={{...ownStyles.joinedCheckContainer}}>
                    <CheckIcon color={Colors.success} size={12}/>
                </View>
                }
                {player.joinedGame && !player.ready &&
                <View style={{...ownStyles.tutorialIconContainer}}>
                    <TutorialIcon color={'rgba(245, 184, 0, 1)'} size={50}/>
                </View>
                }
            </View>
            <View style={{marginTop: Spacing.small}}>
                <StandardText
                    style={{color: 'rgba(177,182,196,1)', fontSize: 14, textAlign: 'center'}}>
                    {player.userAccount ? (player.userAccount.user.firstName.length > 6 ? player.userAccount.user.firstName.substring(0, 6) + '...' : player.userAccount.user.firstName) : 'Seymour'}
                </StandardText>
            </View>
        </View>
    );
}

const renderTeamPlayers = (team, teamIndex, openSeats) => {
    const badges = team.teamPlayers.slice().sort((a, b) => a.seat - b.seat).map((player) => {

        let tutorialText = null;
        if (!player.joinedGame) {
            tutorialText = 'Has not joined yet.';
        } else if (player.joinedGame && !player.ready) {
            tutorialText = 'Going through the tutorial.';
        } else if (player.joinedGame && player.ready) {
            tutorialText = 'Joined and ready to play!';
        }

        return (
            <View style={{zIndex: 2}} key={player.id}>
                {tutorialText ?
                    <Tooltip children={renderBadge(player, teamIndex)} content={tutorialText} customWidth={115}/>
                    : renderBadge(player, teamIndex)
                }
            </View>
        );
    });

    badges.push(...openSeats.map((_, idx) => (
        <View style={{zIndex: 2}} key={'open-seat-' + idx}>
            <Tooltip children={renderBadge(null, teamIndex)} content={'Seat is open'} customWidth={115}/>
        </View>
    )));

    return badges;
};

export default WaitingForPlayers;
